import React from "react";
import Navigation from "../components/Navigation";
//import Counter from "../components/Counter";
//import Projects from "../components/Projects";
//import TestimonialSlider from "../components/TestimonialSlider";
import  { useEffect, useState } from 'react';
import { Link } from "react-router-dom"
import { useNavigate } from 'react-router-dom';

export default function Subscription() {

 

  
    window.scrollTo(0, 0);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [token, setToken] = useState('');
    const [category, setCategory] = useState('');
   
    const [duration, setDuration] = useState('');
    const [loading, setLoading] = useState(false); // Manage loading state
    const [states, setStates] = useState([]);
      const [cities, setCities] = useState([]);
      const [citys, setCity] = useState('');
      const [state, setState] = useState('');

     // State to hold the selected option and response
  const [selectedOption, setSelectedOption] = useState('');
  const [response, setResponse] = useState('');

  
  // Options for the dropdown and their corresponding responses
  const options = [
    { value: 'Premium10', label: 'Premium10', response: '₦1,000' },
    { value: 'Premium20', label: 'Premium20', response: '₦2,000' },
    { value: 'Premium30', label: 'Premium30', response: '₦3,000' },
  ];

   const nState = localStorage.getItem('state');
   const nCity = localStorage.getItem('city');

  // Handle the selection of a dropdown option
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // Find the response corresponding to the selected option
    const selectedOptionResponse = options.find(option => option.value === selectedValue)?.response || '';
    setResponse(selectedOptionResponse);
    setCategory(selectedValue);
  };
    
    
   
  
    // Load data from localStorage when the component mounts
    useEffect(() => {
      const fetchData = async () => {
        const token = localStorage.getItem('token'); // Get the token from localStorage
       
        

     
        if (!token) {
          
          setError('No token found');
          navigate('/login')
          return;
        }
        
        if(!category){

          setError('Enter your Premium Category');
          return;
        }
        if(!duration){

          setError('Enter your Premium Category');
          return;
        }

        
      }

      fetchData();
    },
        []);
  
       
        
  
    const navigate = useNavigate();


    const handleChange = async (event) => {
      const value = event.target.value;
      setDuration(value);
   
    

       

      }
  
    // const handleChange = (e) => {
    //   const { name, value } = e.target;
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     [name]: value,
    //   }));
    // };

    const city = nCity;
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true); // Set loading to true when submission starts
      try {
       //console.log(email);
       if(!category){

        setError('Enter your Premium Category');
        return;
      }
      if(!duration){

        setError('Enter your Duration');
        return;
      }

       const token = localStorage.getItem('token'); // Get the token from localStorage
       const formData = {
        duration,
        city,
        category,
      };
        const response = await fetch(`${process.env.REACT_APP_API_URL}subscribe`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
  
        if (!response.ok) {
          const result = await response.json();
          
           setError(result.message.email || result.message ||

             'Failed to Verify Email');
            
              return;
          
          
        }
        const result = await response.json();
        if(result.message === 'Success'){

          navigate('/dashboard');
          return;
        }
  
        const data = await response.json();
        //const token = data.data.token;
        //console.log(token);
        //localStorage.setItem('token', token);
        
       
       
        setError(null);
        setSuccess(true);
      } catch (error) {
        setError(error.message);
        setSuccess(false);
      }
        finally{

          setLoading(false); // Set loading to false when submission ends
        }
      
      
    };

  
    
  
 

return (
  <>
    <div className="position-relative p-0">
      {/* <Navigation /> */}

      <div class="loader-wrapper"><div class="lds-ring"><div>
      <div class="card card-authentication1 mx-auto my-5">
        <div class="card-body">
        <div class="card-content p-2">
          <div class="text-center">
            <img src="assets/images/logo-lf.png" alt="logo icon" />
           
          </div>
          <div class="card-header text-center py-4">
            <p class="text-white mb-0"> </p>
          </div>
          <div class="card-title text-uppercase text-center py-3">Subscribe Now</div>
            <form onSubmit={handleSubmit} id="MyForm" >
            <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">Premium Listing</label>
              <div className="position-relative has-icon-right">
                
                <select className="form-control input-shadow"  id="dropdown" name="category" value={selectedOption} onChange={handleSelectChange}>
                  <option value="">Select an option</option>
                  {options.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                </div>
                </div>
           

              <div className="form-group">
              <label for="state" className="sr-only">State</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="state" value={nState} onChange={handleChange}>
                    <option value={nState}>{nState}</option>
                 
                    
                </select>
                
                <div className="form-control-position">
                  <i className="icon-location"></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="city" className="sr-only">City/Location</label>
              <div className="position-relative has-icon-right">
                <select type="text" className="form-control input-shadow" name="city" value={nCity} onChange={(event) => setCity(event.target.value)} >
                <option>{nCity}</option>
                </select>
                <div className="form-control-position">
                  <i className="icon-location"></i>
                </div>
              </div>
              </div>
                <div className="form-group">
                <label for="exampleInputUsername" className="sr-only">Premium Listing</label>
                <div className="position-relative has-icon-right">
                  
                    <input className="form-control input-shadow" id="response" type="text" value={response} readOnly />   
               
                </div>
                </div>
                <div className="form-group">
                  <label for="exampleInputUsername" className="sr-only">Duration</label>
                  <div className="position-relative has-icon-right">
                    <select className="form-control input-shadow" name="duration" value={duration.duration} onChange={(event) => setDuration(event.target.value)}>
                        <option vallue="">Select Duration</option>
                        <option value="Monthly">Monthly</option>
                        
                    </select>
                  
                  <div className="form-control-position">
                    <i className="icon-location"></i>
                  </div>
                </div>
                </div>

              
                <button type="submit" class="btn btn-light btn-block" disabled={loading} >{loading ? 'Processing' : 'PAY NOW'}</button>
                <br />
                {error && <div 
                  style={{
                    color: '#D8000C',
                    backgroundColor: '#FFD2D2',
                    border: '1px solid #D8000C',
                    borderRadius: '5px',
                    padding: '10px 15px',
                    marginTop: '10px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                  }}
                >{error}</div>}
                {success && <div 
                
                  style={{
                    color: 'green',
                    backgroundColor: '#FFD2D2',
                    border: '1px solid green',
                    borderRadius: '5px',
                    padding: '10px 15px',
                    marginTop: '10px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                  }}>Subscription Successful</div>}
                
          
                <div class="text-center mt-5">NOTE!</div>
                <div class="card-footer text-center py-2">
                  <p class="text-white mb-0"><i>Kindly note that subscription is monthly</i> </p>
                </div>
          
          </form>
          </div>
          </div>
          <Link to="../dashboard" className="btn btn-light btn-sm">Back To Dashboard</Link>
          <br />
          </div>
        
        
        {/* <a href="javaScript:void();" class="back-to-top"><i class="fa fa-angle-double-up"></i> </a> */}
        </div>
        </div>
        </div>
        </div>
  </>
)

}
