// src/components/Logout.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Login from "../pages/Login"

const Logout = () => {
  const navigate = useNavigate();

 
    // Clear the token from localStorage or sessionStorage
    localStorage.removeItem('token');
    localStorage.removeItem('scrollPosition');
    localStorage.removeItem('firstname');
    localStorage.removeItem('city2');
    // You can also clear other user-related data if needed
    // localStorage.removeItem('user');

    // Redirect to the login page
    navigate('/login');
  

  return (
    <Login />
  );
};

export default Logout;
