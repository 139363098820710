import React from "react";
import { Link } from "react-router-dom";

export default function Navigation() {
  return (
    <>
    <div id="wrapper">
    <div id="sidebar-wrapper" data-simplebar="" data-simplebar-auto-hide="true">
     <div className="brand-logo">
     <Link to={"/"} className="zmdi zmdi-view-dashboard">
     <img src="assets/images/logo-lf.png" className="logo-icon" alt="logo icon" />    
          </Link>
      
       
       
   
   </div>
   <ul className="sidebar-menu do-nicescrol">
      <li className="sidebar-header"></li>
      <li>
      <Link to={"/"} className="zmdi zmdi-view-dashboard">
           Dashboard
          </Link>
        
      </li>
      <li>
      <Link to={"/subscription"} className="zmdi zmdi-view-dashboard">
          Subscription
          </Link>
        
      </li>

      <li>
      <Link to={"/logout"} className="zmdi zmdi-view-dashboard">
          Logout
          </Link>
      </li>

      
      
	  
     
    </ul>
   
   </div>
   </div>
   </>
  );
}
