import reportWebVitals from "./reportWebVitals";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Payment from "./pages/Payment";
import Subscription from "./pages/Subscription";
import HomePage from "./pages/HomePage";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RegisterStation from "./pages/RegisterStation";
import StationRegistration from "./pages/StationRegistration";
import EditStationRegistration from "./pages/EditStationRegistration";
import VerifyEmail from "./pages/VerifyEmail";
import SwitchCity from "./pages/SwitchCity";
import StationDetails from "./pages/StationDetails";
import StationDashboard from "./pages/StationDashboard";
import UploadStationBanner from "./pages/UploadStationBanner";
import FuelCategory from "./pages/FuelCategory";
import ViewFuelStation from "./pages/ViewFuelStation";
import MapLink from "./pages/MapLink";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
//import Subscription from "./pages/Subscription";
import EditFuelCategory from "./pages/EditFuelCategory";
import Dashboard from "./Dashboard";
import Logout from "./components/Logout";
//import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // This includes Popper.js and jQuery

// import Contact from "./pages/Subscription";
// import Testimonial from "./pages/Testimonial";
// import Service from "./pages/Service";
// import Project from "./pages/Project";

const router = createBrowserRouter([
  {
    
    path: "/login",
    element: <Login />,
   
    
  },
  {
    path: "/verify-email",
    element: <VerifyEmail />,
  },
  
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/register-station",
    element: <RegisterStation />,
  },
  {
    path: "/add-station",
    element: <StationRegistration />,
  },
  {
    path: "/upload-station-banner/:id",
    element: <UploadStationBanner />,
  },
  {
    path: "/view-fuel-station/:id/:user_id",
    element: <ViewFuelStation />,
  },
  {
    path: "/station-dashboard",
    element: <StationDashboard />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/fuel-category/:id",
    element: <FuelCategory />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/update-fuel-category/:id",
    element: <EditFuelCategory />,
  },
  {
    path: "/map-link/:id",
    element: <MapLink />,
  },
  {
    path: "/subscribe",
    element: <Subscription />,
  },
  {
    path: "/station-details/:id",
    element: <StationDetails />,
  },
  {
    path: "/switch-city",
    element: <SwitchCity />,
  },
  {
    path: "/update-fuel-station/:id",
    element: <EditStationRegistration />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  
  {
    
    path: "/",
    element: <HomePage />,
    
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
         
      
    ],
    
  },

  
  
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

