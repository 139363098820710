import React from "react";
//import Navigation from "../components/Navigation2";
//import Counter from "../components/Counter";
import axios from 'axios';
import { ACCESS_TOKEN_NAME, API_BASE_URL } from '../constant/ApiConstant';
import  { useState, useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';



    


    const MyForm = () => {
      const [error, setError] = useState(null);
      const [success, setSuccess] = useState(false);
      const [token, setToken] = useState('');
      const [station_name, setStationName] = useState('');
      const [station_address, setStationAddress] = useState('');
      const [location, setLocation] = useState('');
      const [state, setState] = useState('');
      const [city, setCity] = useState('');
      const [states, setStates] = useState([]);
      const [cities, setCities] = useState([]);
      const [day_list, setDayList] = useState('');
      const [opening_time, setOpeningTime] = useState('');
      const [firstname, setFirstname] = useState('');
      const [closing_time, setClosingTime] = useState('');
      const [map_link, setMapLink] = useState('');
      const [longitude, setLongitude] = useState('');
      const [latitude, setLatitude] = useState('');
      const [manager_name, setManagerName] = useState('');
      const [loading, setLoading] = useState(false); // Manage loading state
      const navigate = useNavigate();
      
      
     //console.log(state);
    
      // Load data from localStorage when the component mounts
     // console.log(state);

      useEffect(() => {
        const fetchData = async () => {
          const token = localStorage.getItem('token'); // Get the token from localStorage
          const firstname = localStorage.getItem('firstname'); // Get the token from localStorage
          
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}get-state`, {
              method: 'GET',
              headers: {
                
                'Content-Type': 'application/json',
              },
            });
            //console.log(response);
            if (!response.ok) {
           //  console.log('not ok')
            }
            
            const result = await response.json();
            //console.log(result);
            
            setStates(result.data);
            
            //const  = result.data;
    
            //localStorage.setItem('city', city);
            
             
          } catch (error) {
            setError(error.message);
           
          }

         

         // console.log(city);


          if (station_address) setStationAddress(station_address);
          if (token) setToken(token);
          if (station_name) setStationName(station_name);
          if (location) setLocation(location);
          if (manager_name) setManagerName(manager_name);
          if (state) setState(state);
          if (opening_time) setOpeningTime(opening_time);
          if (closing_time) setClosingTime(closing_time);
          if (firstname) setFirstname(firstname);
          
          //console.log(email);
          if (!token) {
            
            setError('No token found');
            navigate('/login')
            return;
          }
          

          
        }

        fetchData();
      },
      []);
    
         
          
    
      
    
      const handleChange = async (event) => {
        const value = event.target.value;
        setState(value);
     
        const state = value;
        
        //console.log(value);

        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}get-city/${value}`, {
            method: 'GET',
            headers: {
              
              'Content-Type': 'application/json',
            },
          });
          //console.log(response);
          if (!response.ok) {
           
          }
          
          const result = await response.json();
          //console.log(result);
          
          setCities(result.data);
          
          
  
          
         
           
        } catch (error) {
          setError(error.message);
         
        }
      };

      
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when submission starts
        try {
         //console.log(email);
         if(!location || !manager_name || !opening_time || !day_list || !closing_time || !state || !station_name || !station_address){

          setError('fill all fields');
          return;
        }
         const formData = {
          station_name,
          station_address,
          manager_name,
          opening_time,
          closing_time,
          location,
          state,
          day_list,
          latitude,
          longitude,
          map_link,
          
          
        };

        if(!formData){

          setError('fill all fields');
          return;

        }
        const token = localStorage.getItem('token'); // Get the token from localStorage
          const response = await fetch(`${process.env.REACT_APP_API_URL}add-station`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (!response.ok) {
            const result = await response.json();
            
             setError(result.message.email || result.message ||

               'Something went wrong');
              
                return;
            
            
          }
          const result = await response.json();
          
          navigate(`/upload-station-banner/${result.data.id}`);
         
          setError(null);
          setSuccess(true);
        } catch (error) {
          setError(error.message);
          setSuccess(false);
        }
        finally{

          setLoading(false); // Set loading to false when submission ends
        }
      
        
        
      };
  
    
      
    
   

  return (
    <>
      <div className="position-relative p-0">
        {/* <Navigation /> */}

        <div class="loader-wrapper"><div class="lds-ring"><div>
        <div class="card card-authentication1 mx-auto my-5">
          <div class="card-body">
          <div class="card-content p-2">
            <div class="text-center">
              <img src="assets/images/logo-lf.png" alt="logo icon" />
             
            </div>
            <div class="card-header text-center py-4">
              <p class="text-white mb-0">Dear {firstname} </p>
            </div>
            <div class="card-title text-uppercase text-center py-3">Add Station Information</div>
              <form onSubmit={handleSubmit} id="MyForm" >
              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">Station Name</label>
              <div class="position-relative has-icon-right">
                <input type="text" id="" name="station_name"  value={station_name.station_name} onChange={(e) => setStationName(e.target.value)} class="form-control input-shadow" placeholder="Station Name" />
                {/* <input type="hidden" id="email" name="email"  value={localStorage.getItem('email')} onChange={handleChange} class="form-control input-shadow" /> */}
                <div class="form-control-position">
                  <i class="icon-count"></i>
                </div>
              </div>
              </div>
              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">Station Address</label>
              <div class="position-relative has-icon-right">
                <input type="text" id="" name="station_address"  value={station_address.station_address} onChange={(e) => setStationAddress(e.target.value)} class="form-control input-shadow" placeholder="Enter Station Address" />
                {/* <input type="hidden" id="email" name="email"  value={localStorage.getItem('email')} onChange={handleChange} class="form-control input-shadow" /> */}
                <div class="form-control-position">
                  <i class="icon-count"></i>
                </div>
              </div>
              </div>
              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">Manager Name</label>
              <div class="position-relative has-icon-right">
                <input type="text" id="" name="manager_name"  value={manager_name.manager_name} onChange={(e) => setManagerName(e.target.value)} class="form-control input-shadow" placeholder="Enter Manager Name" />
                {/* <input type="hidden" id="email" name="email"  value={localStorage.getItem('email')} onChange={handleChange} class="form-control input-shadow" /> */}
                <div class="form-control-position">
                  <i class="icon-count"></i>
                </div>
              </div>
              </div>
              
              <div className="form-group">
              <label for="state" className="sr-only">State</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="state" value={state} onChange={handleChange}>
                    <option value="">--Select State--</option>
                    {states.map((option) => (
                    <option value={option.state}>
                        {option.state}
                    </option>
                    ))} 
                    
                    
                </select>
                
                <div className="form-control-position">
                  <i className="icon-location"></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="city" className="sr-only">City/Location</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="location" value={location.location} onChange={(e) => setLocation(e.target.value)}>
                <option value="">--Select City--</option>
                    {cities.map((option) => (
                    <option value={option.city}>
                        {option.city}
                    </option>
                    ))}  
                    
                    
                </select>
                
                <div className="form-control-position">
                  <i className="icon-location"></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">Opening Time</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="opening_time" value={opening_time} onChange={(e) => setOpeningTime(e.target.value)}>
                    <option vallue="">Select Opening Time</option>
                    <option vallue="6:00AM">6:00AM</option>
                    <option vallue="7:00AM">7:00AM</option>
                    <option vallue="8:00AM">8:00AM</option>
                    <option vallue="9:00AM">9:00AM</option>
                    
                    
                </select>
                
                <div className="form-control-position">
                  <i className=""></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">Closing Time</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="opening_time" value={closing_time.closing_time} onChange={(e) => setClosingTime(e.target.value)}>
                    <option vallue="">Select Closing Time</option>
                    <option vallue="5:00PM">5:00PM</option>
                    <option vallue="6:00PM">6:00PM</option>
                    <option vallue="7:00PM">7:00PM</option>
                    <option vallue="8:00PM">8:00PM</option>
                    <option vallue="9:00PM">10:00PM</option>
                    
                    
                </select>
                
                <div className="form-control-position">
                  <i className=""></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">Days</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="day_list" value={closing_time.day_list} onChange={(e) => setDayList(e.target.value)}>
                    <option vallue="">Select Days</option>
                    <option vallue="Mon-Sat">Mon - Sat</option>
                    <option vallue="Mon-Sun">Mon - Sun</option>
                    
                    
                    
                </select>
                
                <div className="form-control-position">
                  <i className=""></i>
                </div>
              </div>
              </div>

              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">Latitude </label>
              <div class="position-relative has-icon-right">
                <input type="text" id="" name="latitude"  value={latitude.latitude} onChange={(e) => setLatitude(e.target.value)} class="form-control input-shadow" placeholder="Latitude" />
                {/* <input type="hidden" id="email" name="email"  value={localStorage.getItem('email')} onChange={handleChange} class="form-control input-shadow" /> */}
                <div class="form-control-position">
                  <i class="icon-count"></i>
                </div>
              </div>
              </div>
              
              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">Longitude </label>
              <div class="position-relative has-icon-right">
                <input type="text" id="" name="longitude"  value={longitude.longitude} onChange={(e) => setLongitude(e.target.value)} class="form-control input-shadow" placeholder="Longitide" />
                {/* <input type="hidden" id="email" name="email"  value={localStorage.getItem('email')} onChange={handleChange} class="form-control input-shadow" /> */}
                <div class="form-control-position">
                  <i class="icon-count"></i>
                </div>
              </div>
              </div>

             
              

              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">Station Map Link </label>
              <div class="position-relative has-icon-right">
                <input type="text" id="" name="map_link"  value={map_link.map_link} onChange={(e) => setMapLink(e.target.value)} class="form-control input-shadow" placeholder="Map Link" />
                {/* <input type="hidden" id="email" name="email"  value={localStorage.getItem('email')} onChange={handleChange} class="form-control input-shadow" /> */}
                <div class="form-control-position">
                  <i class="icon-count"></i>
                </div>
              </div>
              </div>

             
          
            <button type="submit" class="btn btn-light btn-block" disabled={loading} >{loading ? 'Processing' : 'Proceed'}</button>
            
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {success && <div style={{ color: 'green' }}>Station Added Successful</div>}
            
{/*             
              <div class="text-center mt-5">NOTE!</div> */}
           
            
            </form>
            </div>
            </div>
            <div class="card-footer text-center py-2">
              {/* <p class="text-white mb-0"><i>Kindly check your mail box for the OTP Sent</i> </p> */}
            </div>
            </div>
          
          
          {/* <a href="javaScript:void();" class="back-to-top"><i class="fa fa-angle-double-up"></i> </a> */}
          </div>
          </div>
          </div>
          </div>
    </>
  );
  
  }

// const Login = () => {
//   return (
//     <div>
      
//       <MyForm />
//     </div>
//   );
// };

export default MyForm;
