import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";
import HomePage from "./pages/HomePage";
import Navigation from "./components/Navigation";



function Dashboard() {
  return (
    
      <div className="Dashboard">
        <Header />
        <Navigation />
        <Outlet />
       
        {/* <Footer /> */}
      </div>
    );
  
}

export default Dashboard;
