import React from "react";
import "./VerifyEmail.css";
//import Counter from "../components/Counter";
import axios from 'axios';
import { ACCESS_TOKEN_NAME, API_BASE_URL } from '../constant/ApiConstant';
import  { useState, useEffect, useRef  } from 'react';
import { useNavigate } from 'react-router-dom';



    


    const MyForm = () => {
      window.scrollTo(0, 0);
      const [error, setError] = useState(null);
      const [success, setSuccess] = useState(false);
      const [token, setToken] = useState('');
      const [email, setEmail] = useState('');
      const [firstname, setFirstname] = useState('');
      const [user_role, setUserRole] = useState('');
      // const [otp, setOtp] = useState({
      //   otp:''
      // });
      const [otp, setOtp] = useState(new Array(6).fill(''));
      const inputRefs = useRef([]);
    
      
      
     
    
      // Load data from localStorage when the component mounts
      useEffect(() => {
        const fetchData = async () => {
          const token = localStorage.getItem('token'); // Get the token from localStorage
          const email = localStorage.getItem('email'); // Get the token from localStorage
          const firstname = localStorage.getItem('firstname'); // Get the token from localStorage
          const user_role = localStorage.getItem('user_role'); // Get the token from localStorage


          if (email) setEmail(email);
          if (token) setToken(token);
          if (otp) setOtp(otp);
          if (firstname) setFirstname(firstname);
          if (user_role) setUserRole(user_role);
          //console.log(email);
          if (!token) {
            
            setError('No token found');
            navigate('/login')
            return;
          }
          if(!otp){

            setError('Enter your OTP');
            return;
          }

          
        }

        fetchData();
      },
          []);
    
         
          
    
      const navigate = useNavigate();
    
      // const handleChange = (e) => {
      //   const { name, value } = e.target;
      //   setFormData((prevData) => ({
      //     ...prevData,
      //     [name]: value,
      //   }));
      // };

      
    
      

      const handleChange = (element, index) => {
        const value = element.value.replace(/[^0-9]/g, ''); // Allow only numbers
        if (value.length > 1) {
          return; // Prevent pasting more than one character in a box
        }
    
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
    
        // Move to the next input box if a value was entered
        if (value && index < 5) {
          inputRefs.current[index + 1].focus();
        }
      };

      const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && otp[index] === '') {
          if (index > 0) {
            inputRefs.current[index - 1].focus();
          }
        }
      };
    
    
      const handlePaste = (e) => {
        const pastedData = e.clipboardData.getData('text').slice(0, 6).replace(/[^0-9]/g, '');
        if (pastedData.length === 6) {
          setOtp(pastedData.split(''));
        }
        inputRefs.current[5].focus(); // Move focus to the last input
      };
      const otp_code = otp.join('');
      //console.log(otp_code);
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
         //console.log(email);
         const formData = {
          otp_code,
          email,
        };
          const response = await fetch(`${process.env.REACT_APP_API_URL}verify-email`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (!response.ok) {
            const result = await response.json();
            
             setError(result.message.email || result.message ||

               'Failed to Verify Email');
              
                return;
            
            
          }
    
          const data = await response.json();
          //const token = data.data.token;
          //console.log(token);
          //localStorage.setItem('token', token);
          
          if(localStorage.getItem('user_role') === 'customer'){

            navigate('/dashboard');
            return;
          }
          if(localStorage.getItem('user_role') === 'provider'){

            navigate('/station-dashboard');
            return;
          }
         
          setError(null);
          setSuccess(true);
        } catch (error) {
          setError(error.message);
          setSuccess(false);
        }
        
        
      };
  
    
      
    
   

  return (
    <>
      <div className="position-relative p-0">
        {/* <Navigation /> */}

        <div class="loader-wrapper"><div class="lds-ring"><div>
        <div class="card card-authentication1 mx-auto my-5">
          <div class="card-body">
          <div class="card-content p-2">
            <div class="text-center">
              <img src="assets/images/logo-lf.png" alt="logo icon" />
             
            </div>
            <div class="card-header text-center py-4">
              <p class="text-white mb-0">Dear {firstname} </p>
            </div>
            <div class="card-title text-uppercase text-center py-3">Enter OTP To Verify Your Email</div>
              <form onSubmit={handleSubmit} id="MyForm" >
              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">OTP</label>
              <div class="text-center position-relative has-icon-right">
              <div class="text-center" onPaste={handlePaste} style={{ display: 'flex', gap: '14px', paddingRight: '12px' }}>
                    {otp.map((data, index) => (
                        <input
                          key={index}
                          type="text"
                          maxLength="1"
                          value={data}
                          onChange={(e) => handleChange(e.target, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          ref={(el) => (inputRefs.current[index] = el)}
                          style={{
                            width: '40px',
                            height: '40px',
                            textAlign: 'center',
                            fontSize: '18px',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                          }}
                        />
                      ))}
                  </div>
                <button type="submit" className="verify-btn">
                  Verify OTP
                </button>
              </div>
              </div>
          
          
            
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {success && <div style={{ color: 'green' }}>Verification Successful</div>}
            
            
              <div class="text-center mt-5">NOTE!</div>
           
            
            </form>
            </div>
            </div>
            <div class="card-footer text-center py-2">
              <p class="text-white mb-0"><i>Kindly check your mail box for the OTP Sent</i> </p>
            </div>
            </div>
          
          
          {/* <a href="javaScript:void();" class="back-to-top"><i class="fa fa-angle-double-up"></i> </a> */}
          </div>
          </div>
          </div>
          </div>
    </>
  );
  
  }

// const Login = () => {
//   return (
//     <div>
      
//       <MyForm />
//     </div>
//   );
// };

export default MyForm;
