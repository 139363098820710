import React from "react";
//import Navigation from "../components/Navigation2";
//import Counter from "../components/Counter";
import axios from 'axios';
import  { useEffect, useState } from 'react';
import { Link } from "react-router-dom"
import { useNavigate } from 'react-router-dom';


  const MyForm = () => {
   // window.scrollTo(0, 0);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [states, setStates] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [cities, setCities] = useState([]);
    
    const [formData, setFormData] = useState({
      password: '',
      firstname: '',
      lastname: '',
      category: '',
      confirmPassword: '',
      phone_number: '',
      
      email: '',
      city: '',
      
    });
  
    const navigate = useNavigate();
    useEffect(() => {
      const fetchData = async () => {
        
          try {
              const response = await fetch(`${process.env.REACT_APP_API_URL}get-state`, {
                method: 'GET',
                headers: {
                  
                  'Content-Type': 'application/json',
                },
              });
              //console.log(response);
              if (!response.ok) {
             //  console.log('not ok')
              }
              
              const result = await response.json();
              //console.log(result);
              
              setStates(result.data);
      
              //const  = result.data;
      
              //localStorage.setItem('city', city);
              
               
            } catch (error) {
              setError(error.message);
             
            }
        
      }

      fetchData();
    },
        []);

        
        const handleChange1 = async (event) => {
          const value = event.target.value;
          setState(value);
       
          const state = value;
  
           
  
            try {
              const response = await fetch(`${process.env.REACT_APP_API_URL}get-city/${value}`, {
                method: 'GET',
                headers: {
                  
                  'Content-Type': 'application/json',
                },
              });
              //console.log(response);
              if (!response.ok) {
               
              }
              
              const result = await response.json();
              //console.log(result);
              
              setCities(result.data);
      
              
      
              
             
               
            } catch (error) {
              setError(error.message);
             
            }
  
          
  
          }
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (formData.password !== formData.confirmPassword) {
        setError('Passwords do not match');
        return;
      }
      if (formData.city === "") {
        setError('Select your city');
        return;
      }
      if (formData.category === "") {
        setError('Select your Category');
        return;
      }
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}sign-up-station`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
  
        if (!response.ok) {
          const result = await response.json();
          
           setError(result.message.email || result.message.firstname ||
              result.message.lastname ||result.message.phone_number ||result.message.category || 'Failed to register' );
            
              return;
          
          
        }
  
        const data = await response.json();
        

        if(data.status === "false"){

          setError(error.message);
          return;

        }
        const token = data.data.token;
        const firstname = data.data.firstname;
        //const message = data.message;
        //console.log(token);
        localStorage.setItem('token', token);
        localStorage.setItem('firstname', firstname);
        
        navigate('/verify-email');
       
        setError(null);
        setSuccess(true);
      } catch (error) {
        setError(error.message);
        setSuccess(false);
      }
    };

    
  
  return (
    <>
      <div className="position-relative p-0" style={{marginLeft:"-10px" }}>
        {/* <Navigation /> */}

        <div className="loader-wrapper"><div className="lds-ring"><div>
        <div className="card card-authentication1 mx-auto my-5">
          <div className="card-body">
          <div className="card-content p-2">
            <div className="text-center">
              <img src="assets/images/logo-lf.png" alt="logo icon" />
            </div>
            <div className="card-title text-uppercase text-center py-3">Register as a rep or station owner</div>
              <form onSubmit={handleSubmit} >
              <div className="form-group">
              <label for="firstname" className="sr-only">Firstname</label>
              <div className="position-relative has-icon-right">
                <input type="text" id="" name="firstname" value={formData.firstname} onChange={handleChange} className="form-control input-shadow" placeholder="Enter Firstname" />
                <div className="form-control-position">
                  <i className="icon-user"></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="lastname" className="sr-only">Lastname</label>
              <div className="position-relative has-icon-right">
                <input type="text" id="" name="lastname" value={formData.lastname} onChange={handleChange} className="form-control input-shadow" placeholder="Enter Lastname" />
                <div className="form-control-position">
                  <i className="icon-user"></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">Email</label>
              <div className="position-relative has-icon-right">
                <input type="email" id="exampleInputUsername" name="email" value={formData.email} onChange={handleChange} className="form-control input-shadow" placeholder="Enter Email Address" />
                <div className="form-control-position">
                  <i className="icon-email"></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="phone" className="sr-only">Phone Number</label>
              <div className="position-relative has-icon-right">
                <input type="number" id="exampleInputUsername" name="phone_number" value={formData.phone_number} onChange={handleChange} className="form-control input-shadow" placeholder="Enter Phone Number" />
                <div className="form-control-position">
                  <i className="icon-phone"></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="state" className="sr-only">State</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="state" value={state} onChange={handleChange1}>
                    <option value="">--Select State--</option>
                    {states.map((option) => (
                    <option value={option.state}>
                        {option.state}
                    </option>
                    ))} 
                    
                    
                </select>
                
                <div className="form-control-position">
                  <i className="icon-location"></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="city" className="sr-only">City/Location</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="city" value={formData.city} onChange={handleChange}>
                <option value="">--Select City--</option>
                    {cities.map((option) => (
                    <option value={option.city}>
                        {option.city}
                    </option>
                    ))}  
                    
                    
                </select>
                
                <div className="form-control-position">
                  <i className="icon-location"></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="category" className="sr-only">Fuel Category</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="category" value={formData.category} onChange={handleChange}>
                    <option vallue="">Select Fuel Category</option>
                    <option vallue="Petrol">Petrol Only</option>
                    <option vallue="Diesel">Diesel Only</option>
                    <option vallue="Gas">Gas Only</option>
                    <option vallue="Petrol and Gas">Petrol & Gas</option>
                    <option vallue="Petrol, Diesel and Gas">Petrol, Diesel & Gas</option>
                    <option vallue="Petrol and Diesel">Petrol & Diesel</option>
                    <option vallue="Diesel and Gas">Diesel & Gas</option>
                    
                </select>
                
                <div className="form-control-position">
                  <i className=""></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="exampleInputPassword" className="sr-only">Password</label>
              <div className="position-relative has-icon-right">
              <input
                      type={showPassword ? "text" : "password"} // Toggle between text and password
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      className="form-control input-shadow"
                      placeholder="Enter Password"
                    />
                    <div className="form-control-position" onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                      {/* Change the icon based on the showPassword state */}
                      <i className={showPassword ? "icon-eye" : "icon-eye"}></i>
                    </div>
              </div>
              </div>
              <div className="form-group">
              <label for="exampleInputPassword" className="sr-only">Confirm Password</label>
              <div className="position-relative has-icon-right">
              <input
                      type={showPassword ? "text" : "password"} // Toggle between text and password
                      id="password"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      className="form-control input-shadow"
                      placeholder="Confirm Password"
                    />
                    <div className="form-control-position" onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                      {/* Change the icon based on the showPassword state */}
                      <i className={showPassword ? "icon-eye" : "icon-eye"}></i>
                    </div>
              </div>
              </div>
              <div className="form-group">
              <label for="referral" className="sr-only">Referral Code</label>
              <div className="position-relative has-icon-right">
                <input type="text" id="" name="referral_code" value={formData.referral_code} onChange={handleChange} className="form-control input-shadow" placeholder="Referral Code (Optional)" />
                <div className="form-control-position">
                  <i className=""></i>
                </div>
              </div>
              </div>
            <div className="form-row">
            <div className="form-group col-12">
              <div className="icheck-material-white">
              <input type="checkbox" id="user-checkbox"  />
              <label for="user-checkbox">I Agreed with Terms and Conditions</label>
              </div>
            </div>
            <button type="submit" class="btn btn-light btn-block">Register</button>
            </div>
           
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {success && <div style={{ color: 'green' }}>Registration successful!</div>}
            
            </form>
            </div>
            </div>
            <div className="card-footer text-center py-3">
              <p className="text-warning mb-0">Do you have an account?  <Link to={"../login"}>Login</Link></p>
            </div>
            </div>
          
          
          <a href="javaScript:void()" className="back-to-top"><i className="fa fa-angle-double-up"></i> </a>
          </div>
          </div>
          </div>
          </div>
    </>
  );
};

export default MyForm;

