import React from "react";
//import Navigation from "../components/Navigation2";
//import Counter from "../components/Counter";
import axios from 'axios';
import { ACCESS_TOKEN_NAME, API_BASE_URL } from '../constant/ApiConstant';
import  { useState, useEffect  } from 'react';
import {useParams, useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';


function MapLink() {

    const navigate = useNavigate();

    const { id } = useParams();
    const [map, setData] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        
        const fetchData = async () => {
          const token = localStorage.getItem('token'); // Get the token from localStorage
          //const firstname = localStorage.getItem('firstname'); // Get the token from localStorage
          


          //console.log(id);
          
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}get-map-link/${id}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            });
            //console.log(response);
            if (!response.ok) {
              navigate('/login')
            }
            
            const data = await response.json();
            console.log(data.data);
             setData(data.data);
            
            //const sanitizedHtml = DOMPurify.sanitize(map);

            //console.log(formData);
          
           
          } catch (error) {
            setError(error.message);
            
          }

          
          //console.log(email);
          if (!token) {
            
            setError('No token found');
            navigate('/login')
            return;
          }
          

          
        }

        fetchData();
      },
      [id]);
      
      
       

      


    return (
        <div style={{ width: '100%', height: '700px' }}>
        <iframe
          title="Google Map"
          src={`${map}`}
          style={{ border: 0, width: '100%', height: '100%' }}
          allowFullScreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        />
        
      </div>
    );
  }
  
  export default MapLink;
  
