import React from "react";
//import Navigation from "../components/Navigation2";
//import Counter from "../components/Counter";
import axios from 'axios';
import { ACCESS_TOKEN_NAME, API_BASE_URL } from '../constant/ApiConstant';
import  {useMemo, useState, useEffect  } from 'react';
import {useParams, useNavigate } from 'react-router-dom';



    
      const MyForm = () => {
        //window.scrollTo(0, 0);
        const [formData, setFormData] = useState({ 
         station_rep: '', 
          fuel_price: '', fuel_status:'', fuel_type:'',
          phone_number:'', price_unit:'', activate_status:'', category_name:'' });
        const [loading, setLoading] = useState(true);
        const [formData2, setFormData2] = useState('')

        const [error, setError] = useState(null);
        const [success, setSuccess] = useState(false);
        const [token, setToken] = useState('');
        
        const [options, setOptions] = useState([]);
        const { id } = useParams();
      
      
     
    
      // Load data from localStorage when the component mounts
      useEffect(() => {
        const fetchData = async () => {
          const token = localStorage.getItem('token'); // Get the token from localStorage
          //const firstname = localStorage.getItem('firstname'); // Get the token from localStorage
          


          //console.log(id);
          
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}get-fuel-category/${id}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            });
            //console.log(response);
            if (!response.ok) {
              navigate('/login')
            }
            
            const data = await response.json();
            //console.log(data);
            
            setFormData({id: data.data.id,fuel_price: data.data.fuel_price, fuel_status: data.data.fuel_status, activate_status: data.data.activate_status,
              fuel_type: data.data.fuel_type, phone_number: data.data.phone_number, price_unit: data.data.price_unit, station_rep: data.data.station_rep, category_name: data.data.category_name
            });

            //console.log(formData);
            setLoading(false);
           
          } catch (error) {
            setError(error.message);
            setLoading(false);
          }

          
          //console.log(email);
          if (!token) {
            
            setError('No token found');
            navigate('/login')
            return;
          }
          

          
        }

        fetchData();
      },
      [id]);

      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };
    
    
      // console.log(formData) ;
          
    
      const navigate = useNavigate();
    
    //   const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevData) => ({
    //       ...prevData,
    //       [name]: value,
    //     }));
    //   };

      
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {

          const token = localStorage.getItem('token'); // Get the token from localStorage

         //console.log(email);
         
          const response = await fetch(`${process.env.REACT_APP_API_URL}update-fuel-category`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (!response.ok) {
            const result = await response.json();
            
             setError(result.message.email || result.message ||

               'Something went wrong');
              
                return;
            
            
          }
    
          const data = await response.json();
          //const token = data.data.token;
          //console.log(token);
          //localStorage.setItem('token', token);
          
          navigate(`/station-details/${data.data.station_id}`);
         
          setError(null);
          setSuccess(true);
        } catch (error) {
          setError(error.message);
          setSuccess(false);
        }
        
        
      };
  
    
      
    
   

  return (
    <>
      <div className="position-relative p-0">
        {/* <Navigation /> */}

        <div class="loader-wrapper"><div class="lds-ring"><div>
        <div class="card card-authentication1 mx-auto my-5">
          <div class="card-body">
          <div class="card-content p-2">
            <div class="text-center">
              <img src="../assets/images/logo-lf.png" alt="logo icon" />
             
            </div>
            {/* <div class="card-header text-center py-4">
              <p class="text-white mb-0">Dear {firstname} </p>
            </div> */}
            <div class="card-title text-uppercase text-center py-3">Update Fuel Category</div>
              <form onSubmit={handleSubmit} id="MyForm" >
              
              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">Fuel Type</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="fuel_type" value={formData.fuel_type} onChange={handleChange}>
                    <option value={formData.fuel_type}>Fuel Type [{formData.fuel_type}]</option>
                    
                </select>
                
                <div className="form-control-position">
                  <i className="icon-user"></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">Fuel Status</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="fuel_status" value={formData.fuel_status} onChange={handleChange}>
                    <option value={formData2.fuel_status}>Select Fuel Status [{formData.fuel_status}]</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    
                    
                    
                </select>
                
                <div className="form-control-position">
                  <i className="icon-user"></i>
                </div>
              </div>
              </div>
              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">Fuel Price</label>
              <div class="position-relative has-icon-right">
                <input type="number" id="" name="fuel_price"  value={formData.fuel_price} onChange={handleChange} class="form-control input-shadow" placeholder="Enter Price Per Lt,Kg" />
                <input type="hidden" id="email" name="id"  value={formData.id} onChange={handleChange} class="form-control input-shadow" />
                <div class="form-control-position">
                  <i class="icon-count"></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">Price Unit</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="price_unit" value={formData.price_unit} onChange={handleChange}>
                    <option value={formData.price_unit}>Select Price Unit - [{formData.price_unit}]</option>
                    <option value="Ltr">Ltr</option>
                    <option value="Kg">Kg</option>
                   
                </select>
                
                <div className="form-control-position">
                  <i className="icon-user"></i>
                </div>
              </div>
              </div>
              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">Phone Number</label>
              <div class="position-relative has-icon-right">
                <input type="number" id="" name="phone_number"  value={formData.phone_number} onChange={handleChange} class="form-control input-shadow" placeholder="Enter Phone Number" />
                {/* <input type="hidden" id="email" name="email"  value={localStorage.getItem('email')} onChange={handleChange} class="form-control input-shadow" /> */}
                <div class="form-control-position">
                  <i class="icon-count"></i>
                </div>
              </div>
              </div>
              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">Person Incharge</label>
              <div class="position-relative has-icon-right">
                <input type="text" id="" name="station_rep"  value={formData.station_rep} onChange={handleChange} class="form-control input-shadow" placeholder="Person In-Charge" />
                {/* <input type="hidden" id="email" name="email"  value={localStorage.getItem('email')} onChange={handleChange} class="form-control input-shadow" /> */}
                <div class="form-control-position">
                  <i class="icon-count"></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">Station Classification</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="category_name" value={formData.category_name} onChange={handleChange}>
                <option value={formData.category_name}>Select Class[{formData.category_name}]</option>
                    <option vallue="Free">Free</option>
                    <option vallue="Premium">Premium</option>
                </select>
                
                <div className="form-control-position">
                  <i className=""></i>
                </div>
              </div>
              </div>
             
              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">Activate For User</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="activate_status" value={formData.activate_status} onChange={handleChange}>
                    <option value={formData.activate_status}>Select Status[{formData.activate_status}]</option>
                    <option value="ACTIVE">ACTIVATE</option>
                    <option value="INACTIVE">DEACTIVATE</option>
                    
                    
                    
                </select>
                
                <div className="form-control-position">
                  
                </div>
              </div>
              </div>
              
          
            <button type="submit" className="btn btn-light btn-block">Update</button>
            
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {success && <div style={{ color: 'green' }}>Station Added Successful</div>}
            
            
              <div class="text-center mt-5">NOTE!</div>
           
            
            </form>
            </div>
            </div>
            <div class="card-footer text-center py-2">
              <p class="text-white mb-0"><i>Kindly ensure your fill your current price</i> </p>
            </div>
            </div>
          
          
          {/* <a href="javaScript:void();" class="back-to-top"><i class="fa fa-angle-double-up"></i> </a> */}
          </div>
          </div>
          </div>
          </div>
    </>
  );
  
  }

// const Login = () => {
//   return (
//     <div>
      
//       <MyForm />
//     </div>
//   );
// };

export default MyForm;
