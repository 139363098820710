import React from "react";
//import Navigation from "../components/Navigation2";
//import Counter from "../components/Counter";
import axios from 'axios';
import { ACCESS_TOKEN_NAME, API_BASE_URL } from '../constant/ApiConstant';
import  { useState, useEffect  } from 'react';
import {Link, useNavigate } from 'react-router-dom';





    const MyForm = () => {
      const [error, setError] = useState(null);
      const [success, setSuccess] = useState(false);
      const [showPassword, setShowPassword] = useState(false);
      const [rememberMe, setRememberMe] = useState(false);
      const [formData, setFormData] = useState({
        password: '',
        email: '',
      });
      const [loading, setLoading] = useState(false); // Manage loading state
      const navigate = useNavigate();

      useEffect(() => {
        const savedEmail = localStorage.getItem('rememberedEmail');
        if (savedEmail) {
          setFormData((prevData) => ({ ...prevData, email: savedEmail }));
          setRememberMe(true); // Set checkbox to checked if email is found
        }
      }, []);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when submission starts
        
     
    
    
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}sign-in`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (!response.ok) {
            const result = await response.json();
            
             setError(result.message.email || result.message.password ||  result.message ||
                 'Invalid Credential');
              
                return;
            
            
          }

          const data = await response.json();
          
          if(data.data.email_verified_at === null){
            const token = data.data.token;
            localStorage.setItem('token', token);
            const email = data.data.email;
            localStorage.setItem('email', email);
            const firstname = data.data.firstname;
            localStorage.setItem('firstname', firstname);
            const user_role = data.data.user_role;
            localStorage.setItem('user_role', user_role);
            
            //console.log(token);
            navigate('/verify-email');
            return;

          }
          const token = data.data.token;
          const email = data.data.email;
          const user_role = data.data.user_role;
          const city = data.data.city;
          const email_verify = data.data.email_verified_at;
          localStorage.setItem('email_verify', email_verify);

          //console.log(token);
          localStorage.setItem('token', token);
          localStorage.setItem('email', email);
          localStorage.setItem('user_role', user_role);
          localStorage.setItem('city', city);

          if (rememberMe) {
            localStorage.setItem('rememberedEmail', formData.email);
          } else {
            localStorage.removeItem('rememberedEmail'); // Clear saved email if unchecked
          }
          

          if(user_role === 'customer'){

            navigate('/dashboard');
            return;
          }
          if(user_role === 'provider'){

            navigate('/station-dashboard');
            return;
          }
        
          
         
         
          setError(null);
          setSuccess(true);
        } catch (error) {
          setError(error.message);
          setSuccess(false);
        }finally{

          setLoading(false); // Set loading to false when submission ends
        }
      };

      const handleRememberMeChange = () => {
        setRememberMe(!rememberMe); // Toggle the remember me state
      };
  
      
    

  return (
    <>
      <div className="position-relative p-0" style={{marginLeft:"-10px" }}>
        {/* <Navigation /> */}

        <div class="loader-wrapper"><div class="lds-ring"><div>
        <div class="card card-authentication1 mx-auto my-5">
          <div class="card-body">
          <div class="card-content p-2">
            <div class="text-center">
              <img src="assets/images/logo-lf.png" alt="logo icon" />
            </div>
            <div class="card-title text-uppercase text-center py-3">Sign In</div>
            <form onSubmit={handleSubmit} id="MyForm">
                <div className="form-group">
                  <label htmlFor="exampleInputUsername" className="sr-only">Username</label>
                  <div className="position-relative has-icon-right">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="form-control input-shadow"
                      placeholder="Enter Email"
                    />
                    <div className="form-control-position">
                      <i className="icon-mail"></i>
                    </div>
                  </div>
                </div>
                
                <div className="form-group">
                  <label htmlFor="exampleInputPassword" className="sr-only">Password</label>
                  <div className="position-relative has-icon-right">
                    <input
                      type={showPassword ? "text" : "password"} // Toggle between text and password
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      className="form-control input-shadow"
                      placeholder="Enter Password"
                    />
                    <div className="form-control-position" onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                      {/* Change the icon based on the showPassword state */}
                      <i className={showPassword ? "icon-eye" : "icon-eye"}></i>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-6">
                    <div className="icheck-material-white">
                      <input
                        type="checkbox"
                        id="user-checkbox"
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                      />
                      <label htmlFor="user-checkbox">Remember me</label>
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn btn-light btn-block" disabled={loading}>
                  {loading ? 'Processing' : 'Sign In'}
                </button>

                {error && <div style={{ color: 'red' }}>{error}</div>}
                {success && <div style={{ color: 'green' }}>Registration successful!</div>}
            </form>
            
            {/* <div class="text-center mt-3">Sign In With</div>
              
            <div class="form-row mt-4">
              <div class="form-group mb-0 col-6">
              <button type="button" class="btn btn-light btn-block"><i class="fa fa-facebook-square"></i> Facebook</button>
            </div>
            <div class="form-group mb-0 col-6 text-right">
              <button type="button" class="btn btn-light btn-block"><i class="fa fa-twitter-square"></i> Twitter</button>
            </div>
            </div> */}
            
            
            </div>
            <div class="card-footer text-center py-3">
            <p class="text-warning mb-0">Forgot Password?  <Link to="../forgot-password">Click Here</Link></p><br />
            </div>
            </div>
            <div class="card-footer text-center py-3">
              <p class="text-warning mb-0">Sign Up? <Link to="../register">Start Here</Link></p><br />
              
            </div>
            </div>
          
          
          {/* <a href="javaScript:void();" class="back-to-top"><i class="fa fa-angle-double-up"></i> </a> */}
          </div>
          </div>
          </div>
          </div>
    </>
  );
  
};

// const Login = () => {
//   return (
//     <div>
      
//       <MyForm />
//     </div>
//   );
// };

export default MyForm;
