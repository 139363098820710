import React from "react";
//import Navigation from "../components/Navigation2";
//import Counter from "../components/Counter";
import axios from 'axios';
import { ACCESS_TOKEN_NAME, API_BASE_URL } from '../constant/ApiConstant';
import  { useState, useEffect  } from 'react';
import {Link, useNavigate } from 'react-router-dom';

const MyForm = () => {
    
    const [showMessage, setShowMessage] = useState(true);
    
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
      otp: '',
      password: '',
      password_confirmation: '',

    });
    const [loading, setLoading] = useState(false); // Manage loading state
    const navigate = useNavigate();

    useEffect(() => {
      const savedEmail = localStorage.getItem('rememberedEmail');
      if (savedEmail) {
        setFormData((prevData) => ({ ...prevData, email: savedEmail }));
       
      }
    }, []);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true); // Set loading to true when submission starts
      
   
  
  
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}reset-password`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
  
        if (!response.ok) {
          const result = await response.json();
          
           setError( result.message ||
               'Email not registered on LocateFuel App');
               const timer = setTimeout(() => {
                setShowMessage(false);
              }, 3000); // 3 seconds delay
          
              // Cleanup the timer when the component unmounts
              return () => clearTimeout(timer);
             
          
          
        } if(response.ok) {

        const data = await response.json();
        
        setSuccess(data.data || "Password has been changed successfully" );
        const timer = setTimeout(() => {
            setShowMessage(false);
            navigate('/login'); // Replace with your target page route
          }, 3000); // 3 seconds delay
      
          // Cleanup the timer when the component unmounts
          return () => clearTimeout(timer);

        
        
        }
       
        setError(null);
        setSuccess(true);
      } catch (error) {
        setError(error.message);
        setSuccess(false);
      }finally{

        setLoading(false); // Set loading to false when submission ends
      }
    };

   
    
  

return (
  <>
    <div className="position-relative p-0" style={{marginLeft:"-10px" }}>
      {/* <Navigation /> */}

      <div class="loader-wrapper"><div class="lds-ring"><div>
      <div class="card card-authentication1 mx-auto my-5">
        <div class="card-body">
        <div class="card-content p-2">
          <div class="text-center">
            <img src="assets/images/logo-lf.png" alt="logo icon" />
          </div>
          <div class="card-title text-uppercase text-center py-3">Change Password</div>
          <form onSubmit={handleSubmit} id="MyForm">
              <div className="form-group">
                <label htmlFor="exampleInputUsername" className="sr-only">OTP</label>
                <div className="position-relative has-icon-right">
                  <input
                    type="number"
                    id="otp"
                    name="otp"
                    value={formData.otp}
                    onChange={handleChange}
                    className="form-control input-shadow"
                    placeholder="Enter OTP"
                  />
                  <div className="form-control-position">
                    <i className="icon-user"></i>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputUsername" className="sr-only">Password</label>
                <div className="position-relative has-icon-right">
                  <input
                     type={showPassword ? "text" : "password"} // Toggle between text and password
                    id=""
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className="form-control input-shadow"
                    placeholder="Enter Password"
                  />
                  <div className="form-control-position" onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                      {/* Change the icon based on the showPassword state */}
                      <i className={showPassword ? "icon-eye" : "icon-eye"}></i>
                    </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputUsername" className="sr-only">Confirm Password</label>
                <div className="position-relative has-icon-right">
                  <input
                     type={showPassword ? "text" : "password"} // Toggle between text and password
                    id="password"
                    name="password_confirmation"
                    value={formData.password_confirmation}
                    onChange={handleChange}
                    className="form-control input-shadow"
                    placeholder="Confirm Password"
                  />
                  <div className="form-control-position" onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                      {/* Change the icon based on the showPassword state */}
                      <i className={showPassword ? "icon-eye" : "icon-eye"}></i>
                    </div>
                </div>
              </div>
              
              


              <button type="submit" className="btn btn-light btn-block" disabled={loading}>
                {loading ? 'Processing' : 'Submit'}
              </button>
              
              {  error && <div style={{

                    color: 'red',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid red',
                    borderRadius: '5px',
                    padding: '10px 15px',
                    marginTop: '10px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'

               }}>{showMessage && JSON.stringify(error)}</div>}

              {success && <div style={{  
              
              
                color: 'green',
                backgroundColor: '#FFDFFF',
                border: '1px solid green',
                borderRadius: '5px',
                padding: '10px 15px',
                marginTop: '10px',
                fontWeight: 'bold',
                textAlign: 'center',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'}}
                >{showMessage && success}</div>}
         
         </form>
          
          {/* <div class="text-center mt-3">Sign In With</div>
            
          <div class="form-row mt-4">
            <div class="form-group mb-0 col-6">
            <button type="button" class="btn btn-light btn-block"><i class="fa fa-facebook-square"></i> Facebook</button>
          </div>
          <div class="form-group mb-0 col-6 text-right">
            <button type="button" class="btn btn-light btn-block"><i class="fa fa-twitter-square"></i> Twitter</button>
          </div>
          </div> */}
          
          
          </div>
          </div>
          <div class="card-footer text-center py-3">
            <p class="text-warning mb-0"
           
                >Login <Link to="../login"> Here</Link></p><br />
            
          </div>
          </div>
        
        
        {/* <a href="javaScript:void();" class="back-to-top"><i class="fa fa-angle-double-up"></i> </a> */}
        </div>
        </div>
        </div>
        </div>
  </>
);

};

// const Login = () => {
//   return (
//     <div>
    
//       <MyForm />
//     </div>
//   );
// };

export default MyForm;

