import React from "react";
//import Navigation from "../components/Navigation2";
//import Counter from "../components/Counter";
import axios from 'axios';
import { ACCESS_TOKEN_NAME, API_BASE_URL } from '../constant/ApiConstant';
import  { useState, useEffect  } from 'react';
import {Link, useParams, useNavigate } from 'react-router-dom';



    


    const MyForm = () => {
      window.scrollTo(0, 0);
      const [error, setError] = useState(null);
      const [success, setSuccess] = useState(false);
      const [token, setToken] = useState('');
      const [banner, setBanner] = useState(null);
      const [firstname, setFirstname] = useState('');
      const [logo, setLogo] = useState('');

      const [previewUrl, setPreviewUrl] = useState(null);
      const [previewUrl2, setPreviewUrl2] = useState(null);
      const [loading, setLoading] = useState(false); // Manage loading state
      const [resizedImage, setResizedImage] = useState(null);
      const [resizedImage2, setResizedImage2] = useState(null);
      const { id } = useParams();
      
      
     
    
      // Load data from localStorage when the component mounts
      useEffect(() => {
        const fetchData = async () => {
          const token = localStorage.getItem('token'); // Get the token from localStorage
          const email = localStorage.getItem('email'); // Get the token from localStorage
          const firstname = localStorage.getItem('firstname'); // Get the token from localStorage


          if (banner) setBanner(banner);
          if (token) setToken(token);
          if (logo) setLogo(logo);
          if (firstname) setFirstname(firstname);
          //console.log(email);
          if (!token) {
            
            setError('No token found');
            navigate('/login')
            return;
          }
          if(!logo){

            setError('Upload Logo');
            return;
          }
          if(!banner){

            setError('Upload Banner');
            return;
          }

          
        }

        fetchData();
      },
          []);
    
        const handleImageChange1 = (e) => {
        
       const selectedFile = (e.target.files[0]);
       if(selectedFile){
        setLogo(selectedFile);
        resizeImage(selectedFile);
        const fileUrl = URL.createObjectURL(selectedFile);
        setPreviewUrl(fileUrl);
        
            }
         };    
        const handleImageChange2 = (e) => {
        const selectedFile2 =  (e.target.files[0]);
        if(selectedFile2){
        setBanner(selectedFile2);
        resizeImage2(selectedFile2);
        const fileUrl2 = URL.createObjectURL(selectedFile2);
        setPreviewUrl2(fileUrl2);
          }
         };  
         
         //resize logo
         const resizeImage = (selectedFile) => {
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);
        
            reader.onload = (event) => {
              const img = new Image();
              img.src = event.target.result;
        
              img.onload = () => {
                const canvas = document.createElement('canvas');
                const maxWidth = 150; // Set the desired maximum width
                const scaleSize = maxWidth / img.width;
                canvas.width = maxWidth;
                canvas.height = 150;
        
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        
                canvas.toBlob((blob) => {
                  setResizedImage(blob);
                }, 'image/jpeg', 0.8); // Quality set to 0.8 (80%)
              };
            };
          };

            //resize banner
          const resizeImage2 = (selectedFile2) => {
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile2);
        
            reader.onload = (event) => {
              const img = new Image();
              img.src = event.target.result;
        
              img.onload = () => {
                const canvas = document.createElement('canvas');
                const maxWidth = 800; // Set the desired maximum width
                const scaleSize = maxWidth / img.width;
                canvas.width = maxWidth;
                canvas.height = img.height * scaleSize;
        
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        
                canvas.toBlob((blob) => {
                  setResizedImage2(blob);
                }, 'image/jpeg', 0.8); // Quality set to 0.8 (80%)
              };
            };
          };
          
    
      const navigate = useNavigate();
    
      // const handleChange = (e) => {
      //   const { name, value } = e.target;
      //   setFormData((prevData) => ({
      //     ...prevData,
      //     [name]: value,
      //   }));
      // };

      
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
         //console.log(email);
        //  const formData = {
        //   station_logo:logo,
        //   station_banner:banner,
        // };
        setLoading(true); // Set loading to true when submission starts
        const station_id = id;
        const formData = new FormData();
        formData.append('station_logo', resizedImage, logo);
        formData.append('station_banner', resizedImage2, banner);
        formData.append('station_id', id);
        
        //console.log(logo);
          const response = await fetch(`${process.env.REACT_APP_API_URL}upload-banner-logo`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
             // "Content-Type": "multipart/form-data",
            },
            body:(formData),
          });
    
          if (!response.ok) {
            const result = await response.json();
            
             setError(result.message.email || result.message ||

               'Failed to Verify Email');
              
                return;
            
            
          }
    
         // const data = await response.json();
          //const token = data.data.token;
          //console.log(token);
          //localStorage.setItem('token', token);
          
          navigate('/station-dashboard');
         
          setError(null);
          setSuccess(true);
        } catch (error) {
          setError(error.message);
          setSuccess(false);
        }
        finally {
            // Revoke the object URL after the image is submitted
            URL.revokeObjectURL(previewUrl);
            URL.revokeObjectURL(previewUrl2);
            setLoading(false); // Set loading to false when submission ends
        }
        
        
      };
  
    
      
    
   

  return (
    <>
      <div className="position-relative p-0">
        {/* <Navigation /> */}
       <div className="loader-wrapper"><div className="lds-ring"><div>
        <div className="card card-authentication1 mx-auto my-5">
        <div className="card-body p-0">
        <div className="card-content p-1">
           <div className="card profile-card-2 " >
            <div className="card-img-block">
                <img className="img-fluid" src={previewUrl2 || `${process.env.REACT_APP_IMAGE_URL}/imgDocs/banner-fuel.jpg`} alt="Card image cap" />
            </div>
            <div className="card-body pt-5">
                <img src={previewUrl || `${process.env.REACT_APP_IMAGE_URL}/imgDocs/profile.jpg`} alt="profile" className="profile" style={{ width: '100px', height: '75px' }} />
                <h5 className="card-title">Upload Station Banner & Logo </h5>
                <p className="card-text">Kindly Upload your station banner and logo to reflect on the dashboard</p>
            </div>
            <form onSubmit={handleSubmit} id="MyForm" >
            
            <div className="form-group">
            
                <label className="col-lg-6 m-0 form-label form-control-label">Upload Logo</label>
                <p className="card-text m-0" style={{ fontSize: '12px', color: 'yellow', paddingLeft:'13px'}}>Please crop your image to square size or 1:1 size before uploading</p>
                <div className="col-lg-12">
                    <input className="form-control" type="file" name="logo" accept="image/*" value={logo.logo} onChange={handleImageChange1} />
                </div>
            </div>
            <div className="form-group">
                <label className="col-lg-12 col-form-label form-control-label">Upload Banner</label>
                <p className="card-text m-0" style={{ fontSize: '12px', color: 'yellow', paddingLeft:'13px'}}>Please use landscape size for this banner image</p>
                <div className="col-lg-12">
                    <input className="form-control" type="file" name="logo" accept="image/*" onChange={handleImageChange2} />
                </div>
            </div>
            <br></br>
            <br></br>
            <button type="submit" className="btn btn-light btn-block" disabled={loading}> {loading ? 'Uploading...Please Wait' : 'Upload'}</button>
            
            {error && <div style={{ color: 'red', textAlign:'center' }}>{error}</div>}
            {success && <div style={{ color: 'green' }}>Uploading Successful</div>}
            
            
              
            </form>
            <hr></hr>
            <div className="text-center mt-5">PROCEED WITHOUT UPLOADING BANNER AND LOGO</div>
           
            <button className="btn btn-success btn-block"><Link to={'/station-dashboard'}>Click Here</Link></button>
          </div>
          </div>
          {/* <a href="javaScript:void();" className="back-to-top"><i className="fa fa-angle-double-up"></i> </a> */}
            </div>
          </div>
        </div>
    </div>
    </div>
    </div>
        
   
    </>
  );
  
  }

// const Login = () => {
//   return (
//     <div>
      
//       <MyForm />
//     </div>
//   );
// };

export default MyForm;
