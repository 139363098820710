import React from "react";
//import Navigation from "../components/Navigation2";
//import Counter from "../components/Counter";
import axios from 'axios';
import { ACCESS_TOKEN_NAME, API_BASE_URL } from '../constant/ApiConstant';
import  { useState, useEffect  } from 'react';
import {useParams, useNavigate } from 'react-router-dom';



    


    const MyForm = () => {
     

      const [formData, setFormData] = useState({ 
        station_name: '', 
         location: '', state1:'', state:'', station_address:'', map_link:'',
         opening_time:'', closing_time:'', manager_name:'',longitude:'', latitude:''});
       const [loading, setLoading] = useState(true);
      // const [formData2, setFormData2] = useState('')

       const [error, setError] = useState(null);
       const [success, setSuccess] = useState(false);
       const [token, setToken] = useState('');
       const [states, setStates] = useState([]);
      const [cities, setCities] = useState([]);
      const [state, setState] = useState('');
       
       const [firstname, setFirstname] = useState([]);
       const { id } = useParams();
      
      
     
    
      // Load data from localStorage when the component mounts
      const handleChange1 = async (event) => {
        const value = event.target.value;
        setState(value);
     
        localStorage.setItem('state', value);
        
        //console.log(value);

        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}get-city/${value}`, {
            method: 'GET',
            headers: {
              
              'Content-Type': 'application/json',
            },
          });
          //console.log(response);
          if (!response.ok) {
           
          }
          
          const result = await response.json();
          //console.log(result);
          
          setCities(result.data);
          
          
  
          
         
           
        } catch (error) {
          setError(error.message);
         
        }
      };
    

          useEffect(() => {
           // window.scrollTo(0, 0);
            const fetchData = async () => {
              const token = localStorage.getItem('token'); // Get the token from localStorage
              //const firstname = localStorage.getItem('firstname'); // Get the token from localStorage
              
              const state1 = localStorage.getItem('state');
              
    
    
              //console.log(id);
              
              try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}get-station-detail/${id}`, {
                  method: 'GET',
                  headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
                });
                //console.log(response);
                if (!response.ok) {
                  navigate('/login')
                }
                
                const data = await response.json();
                //console.log(data);
                
                setFormData({id: data.data.id,station_name: data.data.station_name, station_address: data.data.station_address, state: state, state: state1,
                  manager_name: data.data.manager_name, location: data.data.location, closing_time: data.data.closing_time, map_link: data.data.map_link,
                opening_time: data.data.opening_time, longitude: data.data.longitude, latitude: data.data.latitude });
    
                //console.log(formData);
                setLoading(false);
               
              } catch (error) {
                setError(error.message);
                setLoading(false);
              }
    
              
              //console.log(email);
              if (!token) {
                
                setError('No token found');
                navigate('/login')
                return;
              }

              try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}get-state`, {
                  method: 'GET',
                  headers: {
                    
                    'Content-Type': 'application/json',
                  },
                });
                //console.log(response);
                if (!response.ok) {
               //  console.log('not ok')
                }
                
                const result = await response.json();
                //console.log(result);
                
                setStates(result.data);
                
                //const  = result.data;
        
                //localStorage.setItem('city', city);
                
                 
              } catch (error) {
                setError(error.message);
               
              }
              
              
    
              
            }
    
            fetchData();
          },
          [id]);
          
          //get list of state
          
         // window.scrollTo(0, 0);
          const handleChange = (e) => {
            setFormData({
              ...formData,
              [e.target.name]: e.target.value,
            });
          };



          
        
          // console.log(formData) ;
              
        
          const navigate = useNavigate();
        
        //   const handleChange = (e) => {
        //     const { name, value } = e.target;
        //     setFormData((prevData) => ({
        //       ...prevData,
        //       [name]: value,
        //     }));
        //   };
    
          
        
          const handleSubmit = async (e) => {
            e.preventDefault();
            try {
    
              const token = localStorage.getItem('token'); // Get the token from localStorage
    
             //console.log(email);
             
              const response = await fetch(`${process.env.REACT_APP_API_URL}update-station`, {
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
              });
        
              if (!response.ok) {
                const result = await response.json();
                
                 setError(result.message.email || result.message ||
    
                   'Something went wrong');
                  
                    return;
                
                
              }
        
              const data = await response.json();
              //const token = data.data.token;
              //console.log(token);
              //localStorage.setItem('token', token);
              
              navigate(`/station-details/${id}`);
             
              setError(null);
              setSuccess(true);
            } catch (error) {
              setError(error.message);
              setSuccess(false);
            }
            
            
          };
      
        
    
      
    
   

  return (
    <>
      <div className="position-relative p-0">
        {/* <Navigation /> */}

        <div class="loader-wrapper"><div class="lds-ring"><div>
        <div class="card card-authentication1 mx-auto my-5">
          <div class="card-body">
          <div class="card-content p-2">
            <div class="text-center">
              <img src="https://app.locatebiz.com.ng/assets/images/logo-lf.png" alt="logo icon" />
             
            </div>
            <div class="card-header text-center py-4">
              <p class="text-white mb-0">Dear {firstname} </p>
            </div>
            <div class="card-title text-uppercase text-center py-3">Add Station Information</div>
              <form onSubmit={handleSubmit} id="MyForm" >
              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">Station Name</label>
              <div class="position-relative has-icon-right">
                <input type="text" id="" name="station_name"  value={formData.station_name} onChange={handleChange} class="form-control input-shadow" placeholder="Station Name" />
                {/* <input type="hidden" id="email" name="email"  value={localStorage.getItem('email')} onChange={handleChange} class="form-control input-shadow" /> */}
                <div class="form-control-position">
                  <i class="icon-count"></i>
                </div>
              </div>
              </div>
              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">Station Address</label>
              <div class="position-relative has-icon-right">
                <input type="text" id="" name="station_address"  value={formData.station_address} onChange={handleChange} class="form-control input-shadow" placeholder="Enter Station Address" />
                {/* <input type="hidden" id="email" name="email"  value={localStorage.getItem('email')} onChange={handleChange} class="form-control input-shadow" /> */}
                <div class="form-control-position">
                  <i class="icon-count"></i>
                </div>
              </div>
              </div>
              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">Manager Name</label>
              <div class="position-relative has-icon-right">
                <input type="text" id="" name="manager_name"  value={formData.manager_name} onChange={handleChange} class="form-control input-shadow" placeholder="Enter Manager Name" />
                {/* <input type="hidden" id="email" name="email"  value={localStorage.getItem('email')} onChange={handleChange} class="form-control input-shadow" /> */}
                <div class="form-control-position">
                  <i class="icon-count"></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">State</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="state" value={state.state} onChange={handleChange1}>
                    <option value={formData.state}>Select State - [{formData.state}]</option>
                    {states.map((option) => (
                    <option value={option.state}>
                        {option.state}
                    </option>
                    ))} 
                </select>
                
                <div className="form-control-position">
                  <i className=""></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">City</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="location" value={formData.location} onChange={handleChange}>
                    <option vallue={formData.location}>Select City - [{formData.location}]</option>
                    {cities.map((option) => (
                    <option value={option.city}>
                        {option.city}
                    </option>
                    ))}  
                    
                </select>
                
                <div className="form-control-position">
                  <i className="icon-user"></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">Opening Time</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="opening_time" value={formData.opening_time} onChange={handleChange}>
                    <option vallue={formData.opening_time}>Select Opening Time - [{formData.opening_time}]</option>
                    <option vallue="6:00AM">6:00AM</option>
                    <option vallue="7:00AM">7:00AM</option>
                    <option vallue="8:00AM">8:00AM</option>
                    <option vallue="9:00AM">9:00AM</option>
                    
                    
                </select>
                
                <div className="form-control-position">
                  <i className="icon-user"></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">Closing Time</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="opening_time" value={formData.closing_time} onChange={handleChange}>
                    <option vallue={formData.closing_time}>Select Closing Time [{formData.closing_time}]</option>
                    <option vallue="5:00PM">5:00PM</option>
                    <option vallue="6:00PM">6:00PM</option>
                    <option vallue="7:00PM">7:00PM</option>
                    <option vallue="8:00PM">8:00PM</option>
                    <option vallue="9:00PM">10:00PM</option>
                    
                    
                </select>
                
                <div className="form-control-position">
                  <i className="icon-user"></i>
                </div>
              </div>
              </div>

              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">Latitude </label>
              <div class="position-relative has-icon-right">
                <input type="text" id="" name="latitude"  value={formData.latitude} onChange={handleChange} class="form-control input-shadow" placeholder="Latitude" />
                {/* <input type="hidden" id="email" name="email"  value={localStorage.getItem('email')} onChange={handleChange} class="form-control input-shadow" /> */}
                <div class="form-control-position">
                  <i class="icon-count"></i>
                </div>
              </div>
              </div>
              
              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">Longitude </label>
              <div class="position-relative has-icon-right">
                <input type="text" id="" name="longitude"  value={formData.longitude} onChange={handleChange} class="form-control input-shadow" placeholder="Longitide" />
                {/* <input type="hidden" id="email" name="email"  value={localStorage.getItem('email')} onChange={handleChange} class="form-control input-shadow" /> */}
                <div class="form-control-position">
                  <i class="icon-count"></i>
                </div>
              </div>
              </div>

             

              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">Station Map Link </label>
              <div class="position-relative has-icon-right">
                <input type="text" id="" name="map_link"  value={formData.map_link} onChange={handleChange} class="form-control input-shadow" placeholder="Map Link" />
                {/* <input type="hidden" id="email" name="email"  value={localStorage.getItem('email')} onChange={handleChange} class="form-control input-shadow" /> */}
                <div class="form-control-position">
                  <i class="icon-count"></i>
                </div>
              </div>
              </div>
              
          
            <button type="submit" class="btn btn-light btn-block">Proceed</button>
            
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {success && <div style={{ color: 'green' }}>Station Added Successful</div>}
            
            
              {/* <div class="text-center mt-5">NOTE!</div>
            */}
            
            </form>
            </div>
            </div>
            <div class="card-footer text-center py-2">
              {/* <p class="text-white mb-0"><i>Kindly check your mail box for the OTP Sent</i> </p> */}
            </div>
            </div>
          
          
          {/* <a href="javaScript:void();" class="back-to-top"><i class="fa fa-angle-double-up"></i> </a> */}
          </div>
          </div>
          </div>
          </div>
    </>
  );
  
  }

// const Login = () => {
//   return (
//     <div>
      
//       <MyForm />
//     </div>
//   );
// };

export default MyForm;
