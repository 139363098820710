import React from "react";
//import Navigation from "../components/Navigation2";
//import Counter from "../components/Counter";
import axios from 'axios';
import { ACCESS_TOKEN_NAME, API_BASE_URL } from '../constant/ApiConstant';
import  { useState, useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';



    


    const MyForm = () => {
      window.scrollTo(0, 0);
      const [error, setError] = useState(null);
      const [success, setSuccess] = useState(false);
      const [city1, setCity1] = useState(null);
      const [states, setStates] = useState([]);
      const [cities, setCities] = useState([]);
      const [city, setCity] = useState('');
      const [state, setState] = useState('');
      
      const navigate = useNavigate();
     
    
      // Load data from localStorage when the component mounts
      
       
       const state2 = state;

       useEffect(() => {
        const fetchData = async () => {
          
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}get-state`, {
                  method: 'GET',
                  headers: {
                    
                    'Content-Type': 'application/json',
                  },
                });
                //console.log(response);
                if (!response.ok) {
                 
                }
                
                const result = await response.json();
                //console.log(result);
                
                setStates(result.data);
        
                //const  = result.data;
        
                //localStorage.setItem('city', city);
                
                 
              } catch (error) {
                setError(error.message);
               
              }
          
        }

        fetchData();
      },
          []);
    
   
       const handleChange = async (event) => {
        const value = event.target.value;
        setState(value);
     
      

         

          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}get-city/${value}`, {
              method: 'GET',
              headers: {
                
                'Content-Type': 'application/json',
              },
            });
            //console.log(response);
            if (!response.ok) {
             
            }
            
            const result = await response.json();
            //console.log(result);
            
            setCities(result.data);
    
            
    
            
           
             
          } catch (error) {
            setError(error.message);
           
          }

        

        }

       // localStorage.removeItem('city');
        
      
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
        //console.log(city);
       
            // Update localStorage
       localStorage.setItem('state', state);
       localStorage.setItem('city', city);

       const sss = localStorage.getItem('city');
        //console.log(sss);
            // Update React state
           
         
        
        navigate('/dashboard');
   
          
          
          setError(null);
          setSuccess(true);
        } catch (error) {
          setError(error.message);
          setSuccess(false);
        }
        
        
      };
  
    
      
    
   

  return (
    <>
      <div className="position-relative p-0">
        {/* <Navigation /> */}

        <div class="loader-wrapper"><div class="lds-ring"><div>
        <div class="card card-authentication1 mx-auto my-5">
          <div class="card-body">
          <div class="card-content p-2">
            <div class="text-center">
              <img src="assets/images/logo-lf.png" alt="logo icon" />
             
            </div>
            <div class="card-header text-center py-4">
              <i class="text-white mb-0">Switching city enables you to have access to fuel listing in that city</i>
            </div>
            <div class="card-title text-uppercase text-center py-3">Switch City</div>
              <form onSubmit={handleSubmit} id="MyForm" >
              <div className="form-group">
              <label for="state" className="sr-only">State</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="state" value={state} onChange={handleChange}>
                    <option value="">--Select State--</option>
                    {states.map((option, lable) => (
                    <option key={lable} value={option.state}>
                        {option.state}
                    </option>
                    ))} 
                    
                    
                </select>
                
                <div className="form-control-position">
                  <i className="icon-location"></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="city" className="sr-only">City/Location</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="city" value={city.city} onChange={(e) => setCity(e.target.value)}>
                <option value="">--Select City--</option>
                    {cities.map((option, index) => (
                    <option value={option.city}>
                        {option.city}
                    </option>
                    ))}  
                    
                    
                </select>
                
                <div className="form-control-position">
                  <i className="icon-location"></i>
                </div>
              </div>
              </div>
          
            <button type="submit" class="btn btn-light btn-block">Switch Now</button>
            
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {success && <div style={{ color: 'green' }}>Successful</div>}
            
            
            
           
            
            </form>
            </div>
            </div>
            <div class="card-footer text-center py-2">
              {/* <p class="text-white mb-0"><i>Kindly check your mail box for the OTP Sent</i> </p> */}
            </div>
            </div>
          
          
          {/* <a href="javaScript:void();" class="back-to-top"><i class="fa fa-angle-double-up"></i> </a> */}
          </div>
          </div>
          </div>
          </div>
    </>
  );
  
  }

// const Login = () => {
//   return (
//     <div>
      
//       <MyForm />
//     </div>
//   );
// };

export default MyForm;
