import React, { useEffect } from "react";
import { Link } from "react-router-dom"
//import "./Header.css"
export default function Header() {
  
  return (
    <>
    
  <header className="topbar-nav">
      <nav className="navbar navbar-expand fixed-top">
        <ul className="navbar-nav mr-auto align-items-center">
          <li className="nav-item">
            <a className="nav-link toggle-menu" href="https://app.locatebiz.com.ng/">
            <img src="https://app.locatebiz.com.ng/assets/images/logo-lf.png" alt="LocateFuel" />
            </a>
          </li>
          <li className="nav-item">
         
          
          </li>
        </ul>

        <ul className="navbar-nav align-items-center right-nav-link">
          <li className="nav-item dropdown-lg">
            
          </li>
          <li className="nav-item dropdown-lg">
           
          </li>
          <li className="nav-item">
          {/* <span className="float-right"><Link to="../dashboard" className="btn btn-light btn-sm" >Switch</Link></span> 
            */}
          </li>
          <li className="nav-item">
           
          <span className="float-right"><Link to="../dashboard" className="btn btn-light btn-sm">Home</Link></span> 
          </li>
          <li className="nav-item">
           
          <span className="float-right"><Link to="../logout" className="btn btn-light btn-sm">Logout</Link></span> 
          </li>
        </ul>
      </nav>
    </header>
    <br />
{/* <header className="app-header">
      <div className="logo">
        
      </div>
      <nav className="navigation">
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/about">About</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
      </nav>
    </header> */}
 </>
  );
}
